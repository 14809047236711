.label {
  color: var(--text03);
  width: 10rem;
  min-width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.clickable {
    color: var(--brand01);
    cursor: pointer;
    text-decoration: none;
  }
}
