@import '@work4all/assets/theme/spacing.scss';

.chip {
  font-size: 0.85rem;

  height: $spaceM;
  max-width: 100%;
  overflow: hidden;
}

.constrained {
  &--8 {
    max-width: 8rem;
  }

  &--18 {
    max-width: 18rem;
  }
}

.label {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.caption {
  background-color: var(--ui03);
}

:global(.MuiChip-root) {
  .clear-icon {
    font-size: 1rem;
    color: var(--text03);
    width: 1em;
    height: 1em;
  }
}

:global(.MuiChip-colorDefault) {
  color: var(--text02);

  .clear-icon {
    color: var(--text03);

    &:hover {
      color: var(--text02);
    }
  }
}

:global(.MuiChip-colorPrimary) {
  color: var(--textInverse);
  background: var(--brand01);

  .clear-icon {
    color: var(--ui03);

    &:hover {
      color: var(--textInverse);
    }
  }
}
