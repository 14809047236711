@use '@work4all/assets/theme/breakpoints.scss';
@use '@work4all/assets/theme/elevations.scss';
@use '@work4all/assets/theme/typography.scss';

.root {
  align-items: center;
  padding: 0.5rem;
  padding-top: calc(0.5rem + var(--w4a-safe-area-top, 0px));
  display: flex;
  width: 100%;
  top: 0;
  background: var(--ui01);
  @include elevations.elevationMainHead;
  z-index: 10;
}

.left {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 2rem;
  color: var(--text02);
  margin-right: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.goBackButton {
  padding: 0;
  min-height: 3rem;
  width: 2.5rem;
  min-width: 2.5rem;
}

@media (max-width: breakpoints.$md) {
  .left {
    @include typography.heading4;
    color: var(--text02);
    margin-right: 1rem;
    display: flex;
    align-items: center;
  }
}
