@import '@work4all/assets/theme/spacing.scss';

.title {
  font-weight: 700;
  color: var(--text02);
  font-size: 1rem;
  height: 100%;
  margin-left: $spaceXS;
}

.header {
  margin-right: 0.5rem;
}

.count {
  margin-left: 0.5rem;
}

.groupedRowContainer {
  position: sticky;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
}
