@use '@work4all/assets/theme/typography.scss';
@use '@work4all/assets/theme/spacing.scss';

.dialog {
  max-width: 500px;
}

.caption {
  font-size: 1rem;
}

.captionLink {
  color: var(--brand01);
}

.inputValue {
  margin-top: 0.5rem;
  opacity: 1;
}

.inputValue input {
  color: var(--text01) !important;
  -webkit-text-fill-color: var(--text01) !important;
}

.startAdornment {
  color: var(--text03);
  font-family: inherit;
  cursor: inherit;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-right: 0.2rem;
  white-space: nowrap;
  padding-top: 1rem;
}
