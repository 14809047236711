@import '@work4all/assets/theme/spacing.scss';

$searchInputHeight: 56px;

.with-fullscreen {
  &__search {
    padding: 0;
    z-index: 551;
    position: fixed;
    top: 0;
    width: 100vw;
  }
  &__search-input {
    background-color: unset;
    border-bottom: 1px solid var(--widgetBorder);
    border-radius: 0px;
    height: $searchInputHeight;
    margin-top: var(--w4a-safe-area-top, 0px);
    flex-direction: row;

    &__left-icon {
      margin-left: 8px;
    }
    &__right-icon {
      margin-right: 20px;
    }
  }

  &__dropdown {
    box-shadow: unset;
  }

  &__dropdownInput {
    height: calc($searchInputHeight + var(--w4a-safe-area-top, 0px));
  }

  &__list {
    &__footer {
      position: fixed;
      bottom: 0;
      width: 100vw;
      display: flex;
      justify-content: space-around;
      width: 100%;
      background-color: white;
      border-top: 1px solid var(--widgetBorder);
    }

    height: calc(100vh - 100px);
    max-height: calc(100vh - 100px);
    overflow: auto;
  }

  &__items {
    max-height: unset;
  }
}
