@import '@work4all/assets/theme/spacing.scss';

.button {
  background-color: var(--brand01);
  border-radius: $spaceXXS;
  padding: $spaceS;
  color: #ffffff;
  font-size: $spaceS;
  line-height: $spaceS;
  font-family: inherit;
  font-weight: 900;
  height: $spaceXL;
  text-transform: unset;

  &:disabled {
    opacity: 0.5;
  }
}
