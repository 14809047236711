@import '@work4all/assets/theme/spacing.scss';

.action-button {
  composes: button-text from '../../typography/button-text/ButtonText.module.scss';
  color: var(--brand01);
  height: $spaceXL;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: $spaceS;
  margin-right: $spaceXS;
  text-transform: uppercase;
  width: max-content;

  &:last-child {
    margin-right: 0;
  }

  &__title {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    padding-left: $spaceXS;
    white-space: nowrap;
    text-decoration: none;
  }
}
