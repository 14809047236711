@import '@work4all/assets/theme/mixins.scss';

.root {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: max-content 1fr;
  height: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  cursor: pointer;

  background: var(--ui01);
  box-shadow: 0px 1px 2px var(--elevationColor2);

  .indicator {
    width: 0.25rem;
    background: #499ce0;

    &.grey {
      background: var(--ui05);
    }
    &.blue {
      background: var(--brand01);
    }
    &.red {
      background: var(--error);
    }
    &.yellow {
      background: var(--brand02);
    }
    &.green {
      background: var(--success);
    }
    &.purple {
      background: #6749e0;
    }
  }

  .content {
    padding: 0.5rem;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;

    .subtitle {
      color: var(--text03);
    }

    p {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.grey {
      background: #e0e0e040;
    }
    &.blue {
      background: #499ce033;
    }
    &.red {
      background: #d8717140;
    }
    &.yellow {
      background: #d39d3d40;
    }
    &.green {
      background: #67b44340;
    }
    &.purple {
      background: #6749e040;
    }
  }
}
