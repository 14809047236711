@use '@work4all/assets/theme/typography.scss';
@use '@work4all/assets/theme/elevations.scss';

.mobilePositionsTable {
  width: 100%;
  border-spacing: 0;

  td {
    padding: 0.75rem 0.5rem;
    vertical-align: top;

    &:first-child {
      padding-left: 1rem;
    }
    &:last-child {
      padding-right: 1rem;
    }
    border-bottom: 1px solid var(--ui04);
  }

  @include typography.body1;

  .subline {
    @include typography.caption;
    color: var(--text03);
    width: 100%;
    display: flex;

    .left {
      flex: 1;
      display: flex;
      gap: 2rem;
    }
  }
  .title td {
    font-weight: 700;
    border-bottom: 1px solid var(--ui04);
    color: var(--text03);
  }

  .text01 td {
    color: var(--text01);
  }
}
