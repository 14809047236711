@import '@work4all/assets/theme/spacing.scss';

.chipsContainer {
  display: flex;
  margin-top: $spaceS;
  min-height: $spaceM;
  flex-wrap: wrap;
  gap: $spaceXXS $spaceXS;
}

.container {
  position: relative;
}
