@import '@work4all/assets/theme/elevations.scss';

.button {
  position: absolute;
  left: 50%;
  bottom: 2rem;
  transform: translateX(-50%);
  @include elevation3;
  background-color: var(--brand01);
}
