@import '@work4all/assets/theme/spacing.scss';
@import '@work4all/assets/theme/breakpoints.scss';

.previewWrapper {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  font-size: 1rem;

  .preview {
    padding: 1rem;
    border-radius: 0.25rem;
    transition: background ease 0.2s;

    @media (min-width: $md) {
      max-height: 300px;
      overflow-y: auto;
    }

    &:hover {
      background: var(--ui02);
      cursor: pointer;
    }
  }

  .decorator {
    padding: 1rem;
  }
}

.preLine {
  white-space: pre-line;
}

.textEditor {
  padding-left: 0.5rem;
}
