.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow-y: auto;
  background: var(--ui04);
  gap: 1rem;
  padding: 1rem;
}

.table {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.tableBody {
  background: transparent;
}

.board {
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 0.5rem;
  background: var(--ui02);
  min-height: 300px;
}

.boardContent {
  position: relative;
  flex: 1;
  min-width: 300px;
}

.cardRow {
  border-radius: 0.5rem;
  box-shadow: 0px 4px 8px var(--elevationColor1);
}

.header {
  padding: 0.5rem;
  padding-bottom: 0;
}
