.collapse {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.tBody {
  border: none;
}

.innerInfiniteTable {
  border-left: 1px solid var(--ui04);
}
