.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.tooltipContent {
  width: 100%;
}

.center {
  justify-content: center;
}

.left {
  justify-content: left;
}

.right {
  justify-content: right;
}
