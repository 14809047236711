@media (hover: hover) {
  [role='row'] .button {
    opacity: 0;
  }

  [role='row']:hover .button,
  .button.show {
    opacity: 1;
  }
}

.button {
  display: flex;
  justify-content: center;
}
