.treeItem {
  .content {
    padding: 0.25rem 0.5rem;
  }

  .group {
    margin-left: 2.5rem;
  }

  .iconContainer {
    padding: 0.5rem 0.25rem;
    width: 2rem;

    svg {
      font-size: 1.5rem;
      color: var(--text03);
    }
    &--hidden {
      display: none;
    }
  }

  .actionButtonsWrapper {
    display: flex;
    gap: 8;
  }

  .label {
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0.5rem 0;
  }
}
