@import '@work4all/assets/theme/spacing.scss';

.headerCellRoot {
  position: relative;
  background-color: var(--ui01);

  &.columnDragBefore::after,
  &.columnDragAfter::after {
    content: '';
    display: block;
    position: absolute;
    width: 0.25rem;
    height: 2rem;
    background: var(--brand01);
    border-radius: 0.125rem;
    top: 50%;
    transform: translate(0, -50%);
  }

  &.columnDragBefore::after {
    left: -5px;
  }

  &.columnDragAfter::after {
    right: 1px;
  }

  &[data-sticky-last-left-td]::after {
    content: '';
    display: block;
    background: linear-gradient(to right, rgb(0, 0, 0, 0.1), transparent);
    height: 100%;
    width: 16px;
    position: absolute;
    top: 0;
    right: -16px;
    pointer-events: none;
    opacity: 0;
    transition: 300ms opacity ease;
  }

  &[data-sticky-first-right-td]::before {
    content: '';
    display: block;
    background: linear-gradient(to left, rgb(0, 0, 0, 0.1), transparent);
    height: 100%;
    width: 16px;
    position: absolute;
    top: 0;
    left: -16px;
    pointer-events: none;
    opacity: 0;
    transition: 300ms opacity ease;
  }
}

:global([data-sticky-scroll-left='scroll'])
  .headerCellRoot[data-sticky-last-left-td]::after {
  opacity: 1;
}
:global([data-sticky-scroll-right='scroll'])
  .headerCellRoot[data-sticky-first-right-td]::before {
  opacity: 1;
}

// TODO: noFlex is duplicated. Create single component.
.noFlex {
  flex: unset !important;
}

.cell {
  flex: 1 1 0;
  min-width: 0;
  padding: 0.25rem 0rem;
}

@keyframes fadeRight {
  0% {
    opacity: 0;
    transform: translateX(-$spaceXS);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.highlighted {
  animation: fadeRight 0.2s linear;
}
