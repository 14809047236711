.card {
  display: flex;
  flex: 1;
  max-width: min(65rem, 90vw);
  margin: auto;
  min-height: 100rem;
}

.container {
  display: flex;
  flex: 1;
  padding: 3rem 0 1rem 1rem;
}

.panel {
  height: 100%;
}
