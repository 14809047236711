@use '@work4all/assets/theme/spacing.scss';
@use '@work4all/assets/theme/breakpoints.scss';

.tableWrapper {
  position: relative;
  overflow: auto;
  flex: 1 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: var(--ui01);
}

.wrap {
  display: flex;
  overflow: auto;
  height: 100%;
}
