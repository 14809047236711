@use '@work4all/assets/theme/elevations.scss';
@use '@work4all/assets/theme/typography.scss';

.dialog {
  @include elevations.elevation6;

  max-width: 740px;
}

.dialog-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.dialog-content {
  * {
    @include typography.body1;
  }
}
