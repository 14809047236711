@import '@work4all/assets/theme/spacing.scss';

.wrapper {
  grid-gap: $spaceS;
}

.grid {
  display: grid;
}

.columns {
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}
.outerSize {
  display: inline-block;
}
.flex {
  display: flex;
  flex-direction: row;
}

.flexColumn {
  flex-direction: column;
}

.paddingTop {
  padding-top: $spaceS;
  &--lg {
    padding-top: $spaceS;
  }
  &--md {
    padding-top: 0.75rem;
  }
}

.paddingBottom {
  padding-bottom: $spaceS;
  &--lg {
    padding-bottom: $spaceS;
  }
  &--md {
    padding-bottom: 0.75rem;
  }
  &:last-child {
    padding-bottom: 0;
  }
}
