@import 'libs/assets/theme/spacing.scss';

.buttonWrapper {
  display: flex;
  position: relative;
  align-items: center;
  background-color: rgba(238, 238, 238, 0.5);
  padding: 3px;
  gap: 8px;
  border-radius: 0.5rem 2rem 2rem 0.5rem;
  justify-content: center;
  height: 3.25rem;
}

.button {
  text-transform: none;
  color: white;
  font-weight: 500;
  padding: 0 0 0 6px;
  background: transparent;
  min-width: unset;
  max-width: 12rem;
}

.buttonWrapperNotRounded {
  border-radius: 0.5rem;

  .button {
    max-width: initial;
    flex: 1;
    overflow: hidden;
  }
}

.iconButton {
  padding: 0;
}

.endIcon {
  margin-left: 0;
}

.userIcon {
  height: 2.75rem;
  width: 2.75rem;
}
