@import '@work4all/assets/theme/spacing.scss';

.root {
  display: flex;
  padding: 0;
  height: 100%;
}

.list-item {
  padding: 0;
  margin-right: $spaceXS;

  &:last-child {
    margin-right: 0;
  }
}
