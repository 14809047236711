.fieldsTable > div {
  overflow: unset !important;
}

.fieldsRow {
  height: 2.5rem;
}

.fieldsHeaderCell {
  font-size: 0.85rem;
}

.checkBoxWrap {
  width: 100%;
  display: flex;
  justify-content: center;
}

.checkBox {
  padding: 0;
  justify-content: center;

  & > span:last-child {
    display: none;
  }
}
