.icon {
  color: var(--text03);
  width: 1.5rem;
}

.main {
  display: flex;
  justify-content: start;
  gap: 2.5rem;
}

.iconDiv {
  width: 1.5rem;
}
