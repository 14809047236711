@import '@work4all/assets/theme/mixins.scss';

.red {
  color: red;
}
.container {
  display: flex;
  justify-content: space-between;
}

.beginCell {
  float: left;
  padding-left: 0.25rem;
}

.endCell {
  float: right;
  padding-right: 0.25rem;
}

.table {
  box-shadow: 0px 4px 8px var(--elevationColor1);
  height: 100%;
  width: 100%;
}
