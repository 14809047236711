@import '@work4all/assets/theme/elevations.scss';
@import '@work4all/assets/theme/spacing.scss';

.root {
  width: 100%;
  height: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @include elevation2;
  border-radius: $spaceXXS;
  background-color: var(--ui02);
  background-image: url('../../../../../assets/icons/social_person.svg');
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  overflow: hidden;
}

.actionsWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
