@import '@work4all/assets/theme/spacing.scss';

.infoCardWrapper {
  gap: $spaceS;
  display: grid;
  grid-template-columns: repeat(var(--columns, 2), 1fr);
  padding: $spaceS;
}
.divider {
  padding: $spaceXS;
}

.hist {
  padding: 0 1rem;
  padding-bottom: 2rem;
}
