.eventCalendar {
  :global {
    .mbsc-timeline-resource-col {
      width: 35rem;
    }
    .mbsc-timeline-parent,
    .mbsc-timeline-row {
      height: 2rem;
      align-items: center;
    }

    .mbsc-timeline-row-gutter {
      height: 6px;
    }

    .mbsc-timeline-event-background {
      margin-top: 0;
      top: 0.25rem;
    }

    .mbsc-timeline-day {
      height: 100%;
    }

    .mbsc-timeline-resource-header,
    .mbsc-timeline-resource-footer,
    .mbsc-timeline-resource-title,
    .mbsc-timeline-sidebar-header,
    .mbsc-timeline-sidebar-resource-title,
    .mbsc-timeline-sidebar-footer {
      padding: 0;
    }
  }
}

.eventCalendar {
  @for $i from 1 through 2000 {
    &.leftAreaWidth-#{$i} :global {
      .mbsc-timeline-resource-col {
        width: $i + 0px;
      }
    }
  }
}

@for $i from 1 through 100 {
  .progress-#{$i} {
    :global {
      .mbsc-timeline-event-background:before {
        content: '';
        display: block;
        position: absolute;
        left: 2px;
        bottom: 2px;
        top: 2px;
        width: $i + 0%;
        background-color: rgba(0, 0, 0, 0.125);
      }
    }
  }
}

.eventCalendar.leftAreaCollapsed {
  :global {
    .mbsc-timeline-resource-col {
      width: 0;
      overflow: hidden;
    }
  }
}

$columnWidthStep: 7px;

.eventCalendar {
  @for $i from 1 through 5 {
    &.zoom-#{$i} :global {
      .mbsc-timeline-column,
      .mbsc-timeline-header-column {
        width: $columnWidthStep * $i + $columnWidthStep;
      }
    }
  }
}

.weekend {
  background-image: repeating-linear-gradient(
    132deg,
    transparent,
    transparent 0.25rem,
    var(--ui04) 0.25rem,
    var(--ui04) 0.5rem
  );
}

.workDay {
  background-color: var(--ui01);
}

.selectedDay,
.selectedRow {
  background-color: var(--bgShade1);
}

.resourceCol {
  width: 12rem;
}

.range {
  position: relative !important;
  :global {
    .mbsc-schedule-event-background {
      background-color: var(--ui05);
      height: 2px;

      &:before {
        content: '';
        display: block;
        width: 2px;
        height: 0.5rem;
        background-color: var(--ui05);
        position: absolute;
        left: 0;
      }

      &:after,
      &:hover:after {
        content: '';
        display: block;
        width: 2px !important;
        height: 0.5rem !important;
        background-color: var(--ui05);
        position: absolute;
        right: 0;
      }
    }
  }
}

.MEILENSTEIN {
  :global {
    .mbsc-schedule-event-title {
      display: none;
    }

    .mbsc-schedule-event-background {
      background-color: transparent !important;
      position: relative !important;
      outline: none !important;

      &:before {
        content: '';
        display: block;
        background-color: var(--brand01) !important;
        height: 1rem;
        width: 1rem;
        transform: rotate(45deg);
        margin-top: -0.25rem;
        left: calc(50% - 0.75rem);
        position: absolute !important;
      }
    }
  }
}

.wrapperFullscreen {
  max-width: 100%;
}
