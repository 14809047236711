.ressourceClassCell {
  text-align: center;
  left: 0;
  right: 0;
  top: 0;
  overflow: hidden;
  margin: 0 -0.5rem;
}

.selected {
  border: 2px solid var(--brand01);
  margin: -0.25rem -0.5rem !important;
  padding: 0.125rem 0;
}

.tooltipTable {
  font-size: 1rem;
  width: 100%;
  overflow: hidden;

  td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
