@import '@work4all/assets/theme/spacing.scss';

.logo {
  padding: 0 0 0 $spaceS;
  position: relative;
}

.logo img {
  height: 2rem;
  width: 2rem;
}
