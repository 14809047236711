.table {
  border-spacing: 0;

  &.borderHorizontal {
    td,
    th {
      border-bottom: 1px solid var(--ui04);
    }

    tr:first-child {
      th,
      td {
        border-top: 1px solid var(--ui04);
      }
    }
  }

  &.borderVertical {
    td,
    th {
      border-right: 1px solid var(--ui04);
    }

    td:first-child {
      border-left: 1px solid var(--ui04);
    }
  }

  th {
    font-size: 0.85rem;
    font-weight: 400;
    color: var(--text03);
    position: sticky;
    background-color: var(--ui01);
    top: 0;
  }

  td {
    padding: 0.25rem;
  }
}
