.banderole {
  width: 100%;
  color: var(--text01);
  font-weight: 500;
}

.banderoleContainer {
  padding: 0.25rem 1rem;
  padding-left: 4rem;
  width: 100%;
  background: var(--alert);
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 3rem;
}
