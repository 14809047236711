.root {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.text {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
