.container {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.right {
  padding: 3rem;
}

.image {
  position: relative;
  background-color: var(--ui01);
  min-height: 240px;

  box-shadow: 0px 2px 1rem 0px var(--elevationColor1);
  background-color: var(--ui01);
  border-radius: var(--borderRadius);

  display: flex;
  justify-content: center;
  align-items: center;

  background-repeat: no-repeat;
  background-size: cover;
  aspect-ratio: 1 / 1;
}

.clickable {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.backdrop {
  position: absolute;
  color: var(--ui03);
  width: 160px;
  z-index: 1;
  height: 160px;
}

.imageButton {
  z-index: 2;
}
