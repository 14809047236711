@use '@work4all/assets/theme/breakpoints.scss';
@import '@work4all/assets/theme/spacing.scss';

.card {
  box-shadow: none;
}

.header {
  font-weight: bold;
  color: var(--text01);
  padding-bottom: $spaceS;
}

.controllerWrapper {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 1rem;
  padding-bottom: $spaceS;

  &:last-child {
    padding-bottom: 0;
  }
}
