.maxHeight {
  height: 100%;
}

.wrapper {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 1rem;
  overflow-y: auto;
}
