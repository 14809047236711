@import '@work4all/assets/theme/spacing.scss';
@import '@work4all/assets/theme/mixins.scss';
@import '@work4all/assets/theme/typography.scss';
@import '@work4all/assets/theme/breakpoints.scss';

.editEntityCTA {
  text-decoration: none;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-header-wrapper {
  display: flex;
  flex-direction: row;
}

.text03 {
  color: var(--text03);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.file-meta {
  width: 100%;
  color: var(--textInverse);
  font-family: 'Roboto', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--projectInfoHeight);

  &__right {
    display: flex;
    padding-right: 1rem;
  }

  &--with-paper {
    background-color: var(--ui01);

    &::after {
      pointer-events: none;
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      height: $spaceS;
      bottom: -$spaceS;
      background: linear-gradient(
        180deg,
        var(--elevationColor1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
}

.breadCrumb {
  color: var(--brand01);
  position: absolute;
}

.yellowNote {
  color: var(--alert);
}
