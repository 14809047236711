.hist {
  padding: 0 1rem;
  padding-bottom: 2rem;
}

.avatar {
  width: 13rem;
  height: 13rem;
}

.avatarContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  span {
    font-family: Roboto;
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
  }
}

.avatarSignature {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 2rem;
}
