.mark {
  flex: none;
  width: 1.5rem;
}

.number {
  flex: none;
  width: 1.75rem;
}

.text {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.amount {
  flex: none;
  width: 3.25rem;
  text-align: right;
}

.unit {
  flex: none;
  width: 3.25rem;
}

.close {
  width: 24px;
  margin-left: 0.5rem;
  flex: none;
}
