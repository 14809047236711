@use '@work4all/assets/theme/elevations.scss';

.button {
  height: 100%;
  max-width: 100%;
}

.deselect-icon {
  color: var(--ui05);
  margin-left: 0.5rem;
  flex: none;
}

.button-active {
  background-color: var(--ui02);
}

@media (hover: hover) {
  .standardAction {
    display: none;
  }

  .listItem:hover .standardAction {
    display: block;
  }

  .listItemVisible .standardAction {
    display: block;
  }
}
