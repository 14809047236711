@use '@work4all/assets/theme/spacing.scss';

.popoverPaper {
  width: 482px;
  background-color: var(--ui01);
  border: 1px solid var(--ui02);
  box-shadow: 0px 6px 12px var(--elevationColor3);
  border-radius: 8px;
  padding: 16px 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}
