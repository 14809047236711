@import '@work4all/assets/theme/spacing.scss';
@import '@work4all/assets/theme/typography.scss';

.outerWrap {
  border-left: 1px solid var(--ui04);
  flex: 1;
  display: flex;
  flex-direction: column;
}

.header {
  @include heading4;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--ui04);
  height: calc(3rem + 0px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-top: 1px solid var(--ui04);
  top: 0;
  position: sticky;
  background: var(--ui01);
  z-index: 100;
  box-sizing: content-box;
  height: calc(2.25rem + 1px);

  .titleContainer {
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.scrollContainer {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 4rem;
}
