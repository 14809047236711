@import '@work4all/assets/theme/spacing.scss';

.avatar {
  //background-color: var(--ui04);
  display: flex;
  align-items: center;
}

.placeholder {
  color: var(--ui07);
  background-color: var(--ui03);
}

.sm-b {
}

.m {
  height: $spaceM;
  width: $spaceM;

  .shortName {
    font-size: 0.85rem;
    font-weight: 500;
    line-height: 0.85rem;
  }
}

.l-bold {
  height: $spaceL;
  width: $spaceL;

  .shortName {
    font-size: 0.85rem;
    font-weight: 700;
    line-height: 0.85rem;
  }
}

.l {
  height: $spaceL;
  width: $spaceL;
}

.xl {
  height: $spaceXL;
  width: $spaceXL;
}

.xxl {
  height: $spaceXXL;
  width: $spaceXXL;
}

.xxxl {
  height: $spaceXXXL;
  width: $spaceXXXL;
}

.shortName {
  //color:var(--text02);
  line-height: 0.8;
  text-transform: uppercase;
}
