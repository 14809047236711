@import '@work4all/assets/theme/elevations.scss';

.root {
  display: inline-flex;
  align-items: center;
  width: 100%;

  & > .favoriteButton {
    flex: none;
    align-self: stretch;
    padding: 0 0.75rem;
    border: none;
    background: transparent;
    color: var(--ui05);
    cursor: pointer;

    @media (hover: hover) {
      opacity: 0;
    }

    & > .icon {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
    }

    &:hover,
    &:focus-visible {
      opacity: 1;
    }
  }

  & > .link {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0.5rem 0.75rem 0.5rem 0;
    text-decoration: none;

    & > .linkText {
      text-overflow: ellipsis;
      color: var(--text01);
      &.inactive {
        color: var(--text03);
      }
    }
  }

  &:hover {
    background: rgba(238, 238, 238, 0.5);

    & > .favoriteButton {
      opacity: 1;
    }
  }

  &.favorite {
    & > .favoriteButton {
      opacity: 1;
      color: var(--brand02);
    }
  }
  &.inactive {
    color: var(--text03);
    pointer-events: none;
  }
}
