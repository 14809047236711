.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.75rem;
}

.closeIconButton {
  padding: 0;
}
