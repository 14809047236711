@import '@work4all/assets/theme/spacing.scss';

.widget-group {
  margin: 1rem 0;
}

.group-header-button {
  padding: $spaceXS;
  margin: 0 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
  border: none;
  color: var(--text01);
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1rem;

  &.textInverse {
    color: var(--textInverse);
  }
}

.group-header-button-md {
  gap: 0.25rem;
  padding: 0;
  margin: 2rem 1rem 1rem 1rem;
}

.icon {
  width: 1em;
  height: 1em;
  flex: none;
  transition: transform 0.1s;
  transform: rotate(-90deg);

  &.group-open {
    transform: rotate(0deg);
  }
}
