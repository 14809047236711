.documentTemplatePreview {
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ui01);
}

.documentTemplateWrapper {
  height: 100%;
}

.collapse {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 3rem;
}

.full {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.documentTemplatePreviewBackground {
  position: absolute;
  transform: scale(5);
  color: var(--ui03);
}
