@use '@work4all/assets/theme/spacing.scss';

.mobileToolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 1;
  padding: 1rem;
  left: 0px;
  right: 0px;
  bottom: 0px;
  pointer-events: none;

  .inner {
    display: flex;
    gap: 1rem;
    align-items: center;
    pointer-events: all;
  }
}

.mobileFilters {
  display: flex;
  align-items: center;

  div:first-of-type {
    margin-right: 1rem;
  }
}

.selectedFilters {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  .chips {
    flex: 1;
    overflow-x: scroll;
  }
  .chips::-webkit-scrollbar {
    display: none;
  }
}
