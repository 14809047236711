@import '@work4all/assets/theme/spacing.scss';

.root {
  background-color: #ffe8e3;
  color: #e08d7b;
  padding: $spaceS;
  display: inline-block;
  box-shadow: unset;
  margin-bottom: $spaceS;
  width: 100%;
  text-align: left;
}

.el {
  color: inherit;
}
