.divider {
  padding: 0;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
}

.thumbnail {
  position: relative;
  cursor: pointer;
  aspect-ratio: 16 / 9;
  background-color: var(--ui02);
  border: 1px solid var(--ui05);
  outline: 3px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    border-color: var(--brand01);
    outline-color: var(--brand01);
  }

  & label {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &.dnd {
    &.dragAccept {
      color: var(--brand01);
    }

    &.dragReject {
      color: var(--error);
    }

    &.dragAccept::before,
    &.dragReject::before {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      background: currentColor;
      opacity: 0.25;
    }
  }
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.deleteButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  color: var(--brand01);
  background-color: var(--ui01);
}
