.container {
  resize: none;
  outline: 0;
  border-bottom: none;
  color: var(--text03);
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  display: block;

  cursor: default;

  width: 100%;
  min-width: 0;
  outline: none;
  resize: none;
  display: flex;
  align-items: center;

  ::global {
    .inside-row {
      background-color: red;
    }
  }
}
.lineHeight {
  height: 1.25rem;
  line-height: 1.7rem;
}
