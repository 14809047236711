@import '@work4all/assets/theme/spacing.scss';

.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  &--up-sm {
    flex-direction: row;
  }
  padding: 0 $spaceS;
  border-radius: 0;
  text-transform: none;
}

.item {
  padding: 0;
}

.root {
  display: flex;
  flex-direction: column;
  &--up-sm {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &--sm {
    margin-bottom: 0.75rem;
    margin-top: 0.5rem;
  }
}
.textEl {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1rem;
  > p {
    display: inline;
  }
}

.divider {
  border-color: var(--gray06);
}

.secondary {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.85rem;
  line-height: 1rem;
}
