/**
 * Stack item is either "pushed" or "popped"
 * data-direction attr store current state
   PUSH: transition new element from left to right
   POP: transition old element from right to left
*/

.content {
  height: 100%;
  width: 100%;

  isolation: isolate;
}

.root {
  height: 100%;
  isolation: isolate;
}

.push :global {
  .slide-enter {
    z-index: 1;
    transform: translateX(100%);
  }

  .slide-enter-active {
    transition: transform var(--transitionDuration) cubic-bezier(0, 1, 0, 1);
    transform: translateX(0);
  }
}

.pop :global {
  .slide-exit {
    z-index: 1;
    transform: translateX(0);
  }

  .slide-exit-active {
    transition: all var(--transitionDuration) cubic-bezier(0, 1, 0, 1);
    transform: translateX(100%);
  }
}
