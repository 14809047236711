@import '@work4all/assets/theme/spacing.scss';

.bubble {
  width: $spaceM;
  min-width: $spaceM;
  height: $spaceM;
  border-radius: $spaceM;
  background-color: var(--brand02);
  color: var(--textInverse);
  font-weight: 900;
  font-family: 'Roboto', sans-serif;
  font-size: 0.85rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
