.resourceTimeline {
  :global {
    .mbsc-schedule-color-text {
      text-align: center;
      padding-top: 0.75rem;
    }

    .mbsc-schedule-event-title {
      color: var(--text01);
    }

    .mbsc-windows.mbsc-schedule-event-inner {
      width: 100%;
      text-align: center;
    }
  }
}

.bordered {
  :global {
    .mbsc-timeline-event-background {
      border: 1px solid var(--ui05);
    }
  }
}

@for $i from 1 through 100 {
  .progress-#{$i} {
    :global {
      .mbsc-timeline-event-background:before {
        content: '';
        display: block;
        position: absolute;
        left: 2px;
        bottom: 2px;
        top: 2px;
        width: calc($i * 1% - 4px);
        background-color: rgba(0, 0, 0, 0.125);
        background-color: rgb(60 176 0 / 30%);
      }
    }
  }
}
