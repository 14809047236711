.listIconButton {
  color: var(--text01);
  flex: none;
  padding: 0;
  pointer-events: default;
}

.listIconButtonDisabled {
  pointer-events: none;
}

.listIconButtonGrayed {
  & svg {
    color: var(--text03);
  }
}

.listButton {
  padding: 0;
  justify-content: flex-start;
  pointer-events: default;

  &:hover {
    background: transparent;
  }
}

.listButtonDisabled {
  pointer-events: none;
}
