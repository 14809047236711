@import '@work4all/assets/theme/spacing.scss';

.action {
  font-family: inherit;
  color: var(--brand01);
  font-weight: 900;
  font-size: 0.875rem;
  text-align: center;
  padding: $spaceS $spaceXS;
  margin-right: 0.75rem;
  text-transform: uppercase;
  flex-grow: 1;
  white-space: nowrap;
  flex-basis: 50%;
  &:last-child {
    margin-right: 0;
  }
}
