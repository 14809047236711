@import '@work4all/assets/theme/typography.scss';
@import '@work4all/assets/theme/spacing.scss';

.caption {
  @include caption;
}

.text01 {
  color: var(--text01);
}

.text03 {
  color: var(--text03);
}

.error {
  color: var(--error);
}
