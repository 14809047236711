.process {
  flex: none;
  width: 16.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.startDate {
  flex: none;
  width: 5.5rem;
}

.withPadding {
  padding: 0.25rem 1rem;
}
