@use '@work4all/assets/theme/breakpoints.scss';
@use '@work4all/assets/theme/mixins.scss';
@use '@work4all/assets/theme/elevations.scss';
@use '@work4all/assets/theme/typography.scss';

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  height: 100%;
}

.nothingFound {
  text-align: center;
  font-style: italic;
}

.muted {
  color: var(--ui05);
  font-weight: 700;
  border-bottom: 1px solid var(--ui04);

  &.firstMuted {
    border-top: 1px solid var(--ui04);
  }
}

.headerRow {
  position: sticky;
  top: 0;
  background: var(--ui01);
  z-index: 1;
}

.close {
  flex: none;
  width: 2.25rem;
}

.deselect-icon {
  color: var(--ui05);
  margin-left: 0.5rem;
  flex: none;
}
