.container {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;

  .mobile & {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    grid-gap: 0 1rem;
    padding: 1.25rem;
    box-shadow: 0px 2px 12px var(--elevationColor1);
  }
}
