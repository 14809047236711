.content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.center {
  display: flex;
  justify-content: center;
  flex: 1;
}
