@import '@work4all/assets/theme/spacing.scss';

.gridControl {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 2;
}
.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  // grid-gap: $spaceS;
}

.right {
  width: 16.68rem;
  height: 100%;
}

.inlineRadio {
  width: auto;
}
