.root {
  position: fixed;
  inset: 0;
  background-size: cover;
  background-origin: content-box;
  background-repeat: no-repeat;
  background-color: var(--topBarBg);
}

.blend {
  background-color: var(--topBarBg);
  background-blend-mode: color;
}
