@import '@work4all/assets/theme/spacing.scss';

.action {
  &:disabled {
    opacity: 0.5;
  }
}

.horizontalPadding {
  &--xs {
    padding-left: $spaceXS;
    padding-right: $spaceXS;
  }

  &--s {
    padding-left: $spaceS;
    padding-right: $spaceS;
  }
}

.verticalPadding {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.iconWrapper {
  display: flex;
  align-items: center;
  margin-right: $spaceXXS;
}

.title {
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text01 {
  color: var(--text01);
}

.text03 {
  color: var(--text03);
}

.textInverse {
  color: var(--textInverse);
}

.primary {
  color: var(--brand01);
}

.error {
  color: var(--error);
}

.left {
  justify-content: left;
}

.center {
  justify-content: center;
}

.right {
  justify-content: right;
}
