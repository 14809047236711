@use '@work4all/assets/theme/elevations.scss';

.settings-popover {
  margin: 0 0.3rem;
  @include elevations.elevation3;
}

.settings-paper {
  padding: 0 1rem;
  border-radius: 0.5rem;
}
