@import '@work4all/assets/theme/elevations.scss';

.wrapper {
  overflow: hidden;
  width: 100%;
  max-width: 1216px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;

  &Fullscreen {
    max-width: 100vw;
  }
}

.wrapperSmall {
  width: 100%;
}

.transition {
  position: absolute;
  right: 0;
  top: 0;
}

.paper {
  height: 100%;
  max-width: 100%;
  border-radius: 0;
  box-shadow: 0;
  @include elevation6;
  display: flex;
  flex-direction: column;
}

.contentWrap {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100% - var(--w4a-safe-area-top, 0px));
  padding-top: var(--w4a-safe-area-top, 0px);
  padding-bottom: var(--w4a-safe-area-bottom, 0px);
  padding-right: var(--w4a-safe-area-right, 0px);
  padding-left: var(--w4a-safe-area-left, 0px);
}

.breadcrumbs-root {
  padding-top: var(--w4a-safe-area-top, 0px);
}
