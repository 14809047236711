@use '@work4all/assets/theme/typography.scss';

.control {
  background-color: var(--ui01);
  height: 100%;
  overflow-y: auto;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.children {
  width: 100%;
}

.titleWrap {
  display: flex;
  padding: 0 1rem;
  height: 3.25rem;
}

.title {
  @include typography.body2SemiBold;
  width: 100%;
  padding: 0.25rem 0;
}
