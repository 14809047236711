.eventCalendar {
  :global {
    .mbsc-timeline-resource-col {
      width: 35rem;
    }

    .mbsc-flex-1-0 {
      height: 100%;
    }

    .mbsc-timeline-resources {
      box-shadow: 0 0 1rem #0000005c;
    }

    .mbsc-timeline-parent,
    .mbsc-timeline-row {
      height: 2rem;
      align-items: center;
      flex: none;
    }

    .mbsc-timeline-row-gutter {
      height: 6px;
    }

    .mbsc-timeline-event-background {
      margin-top: 0;
      top: 0.25rem;
    }

    .mbsc-timeline-day {
      height: 100%;
    }

    .mbsc-timeline-resource-header,
    .mbsc-timeline-resource-footer,
    .mbsc-timeline-resource-title,
    .mbsc-timeline-sidebar-header,
    .mbsc-timeline-sidebar-resource-title,
    .mbsc-timeline-sidebar-footer {
      padding: 0;
    }

    .mbsc-timeline-resource-title {
      height: 100%;
    }

    .mbsc-timeline.mbsc-ltr .mbsc-schedule-event-inner {
      padding: 0;
    }

    .mbsc-timeline-header-month-text,
    .mbsc-timeline-header-text,
    .mbsc-timeline-header-week-text,
    .mbsc-timeline-resource-title,
    .mbsc-calendar-width-md .mbsc-schedule-event-all-day-title {
      font-weight: 400;
      font-size: 0.85rem;
    }
  }
}

.eventCalendar {
  @for $i from 1 through 2000 {
    &.leftAreaWidth-#{$i} :global {
      .mbsc-timeline-resource-col {
        width: $i + 0px;
      }
    }
  }

  @for $i from 1 through 100 {
    .progress-#{$i} :global {
      .mbsc-schedule-event-background:before {
        content: '';
        display: block;
        position: absolute;
        left: 2px;
        bottom: 2px;
        top: 2px;
        width: calc($i * 1% - 4px);
        background-color: rgba(0, 0, 0, 0.125);
      }
    }
  }
}

.eventCalendar.leftAreaCollapsed {
  :global {
    .mbsc-timeline-resource-col {
      width: 0 !important;
      overflow: hidden;
    }
  }
}

$columnWidthStep: 7px;

.eventCalendar {
  @for $i from 1 through 5 {
    &.zoom-#{$i} :global {
      .mbsc-timeline-column,
      .mbsc-timeline-header-column {
        width: $columnWidthStep * $i + $columnWidthStep;
      }
    }
  }
}

.weekend {
  background-color: var(--ui03);
}

.workDay {
  background-color: var(--ui02);
}

.resourceCol {
  width: 12rem;
}

.range {
  position: relative !important;
  :global {
    .mbsc-schedule-event-background {
      background-color: var(--ui05);
      height: 0.3rem;

      &:before {
        content: '';
        display: block;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent var(--ui05) transparent;
        transform: rotate(-45deg);
        position: absolute;
        left: -0.5rem;
      }

      &:after,
      &:hover:after {
        content: '';
        display: block;
        width: 0px !important;
        height: 0px !important;
        background: transparent !important;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent var(--ui05) transparent;
        transform: rotate(45deg);
        position: absolute;
        right: -0.5rem;
      }
    }
  }
}

.MEILENSTEIN {
  :global {
    .mbsc-schedule-event-background {
      background-color: transparent !important;
      position: relative !important;
      outline: none !important;

      &:before {
        content: '';
        display: block;
        background-color: var(--brand01) !important;
        height: 1rem;
        width: 1rem;
        transform: rotate(45deg);
        margin-top: -0.25rem;
        left: calc(50% - 0.75rem);
        position: absolute !important;
      }
    }
  }
}

.wrapperFullscreen {
  max-width: 100%;
}
