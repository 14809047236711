.wrapper {
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  // when cell is dragged seperator is the only static content of cell.
  // everything else has fixed position.
  // when in "dragged" mode: "flexbox" allows to make cell look the same way when it's not dragged.
  display: flex;
  flex: none;
  justify-content: flex-end;
  margin-right: -0.5rem;
  z-index: 10;
  height: 100%;
}

.seperator {
  width: 1px;
  height: 100%;
  background-color: var(--ui04);
}
