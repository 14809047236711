.normalItemWrapper {
  display: flex;

  .checkBoxIcon {
    margin-right: 0.25rem;
  }
}

.nestedItemWrapper {
  display: flex;
  justify-content: space-between;

  .chevronRightIcon {
    color: var(--text03);
  }
}

.label {
  word-break: break-all;
}
