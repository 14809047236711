@import '@work4all/assets/theme/spacing.scss';

.userInfo {
  display: flex;
  flex-direction: column;
  margin-left: $spaceXS;
}

.buttonPadding {
  padding: 0.5rem;
}

.page {
  padding-top: var(--w4a-safe-area-top, 0px);
  padding-right: var(--w4a-safe-area-right, 0px);
  padding-left: var(--w4a-safe-area-left, 0px);
}

.bottomWrapper {
  position: sticky;
  bottom: 0;
  padding: $spaceS;
  margin-top: auto;
}
