.treeItem {
  .content {
    padding: 0.25rem 0.5rem;
    min-height: 2.25rem;
  }

  .group {
    margin-left: 2.5rem;
  }

  .iconContainer {
    padding: 0.5rem 0.25rem;
    margin: -0.5rem 0;
    width: 2rem;

    svg {
      font-size: 1.5rem;
      color: var(--text03);
    }
    &--hidden {
      display: none;
    }
  }

  .label {
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .selected {
    .label {
      color: var(--brand01);
    }

    & ~ .group .selected {
      background-color: transparent;
      .label {
        color: var(--text01);
      }
    }
  }
}
