@import '@work4all/assets/theme/spacing.scss';
@import '@work4all/assets/theme/mixins.scss';

.wrapper {
  background-color: var(--ui02);
  border: 1px solid var(--ui04);
  border-radius: $spaceXXS;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  width: 100%;
  padding: $spaceXXS $spaceXS;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: var(--labeledInputHeight);
  position: relative;
  cursor: pointer;
  width: 100%;

  &.focused {
    border: 1px solid var(--brand01);
    background-color: var(--ui01);
  }

  @include errorStyle;
}

.left {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  padding: $spaceXXS $spaceXS;
  outline: none;
  border: none;
}

.label {
  top: 0;
  left: $spaceXS;
  font-family: inherit;
  transform: translateY(100%);
  cursor: inherit;
  max-width: calc(100% - 2 * 0.5rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1;
  color: var(--text03) !important;

  &--shrink {
    color: var(--text02) !important;
    top: $spaceXXS;
    transform: initial;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    z-index: 1; //give it a higher zindex then the sibligs elswise the browsers autofill styles may hide the label
  }
}

.text01 {
  color: var(--text01) !important;
}

.buttonWrapper {
  width: 100%;
  flex: 1;
}

.button {
  justify-content: space-between;
  padding: 0;
  min-width: 0;
  min-height: 2.4375rem;
  gap: 0.5rem;
  background-color: transparent;
  width: 100%;
  overflow: hidden;

  &:hover {
    background-color: transparent;
  }

  &::before {
    content: unset;
  }

  &::after {
    content: unset;
  }
}

.buttonLeftContent {
  padding: 1rem 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex: 1;
  overflow: hidden;
  width: 100%;
  overflow: hidden;
}

.buttonLeftContentOverlapped {
  @include ellipsis;
}

.buttonRightContent {
  display: flex;
}

.multiline {
  height: fit-content;
  min-height: 2.4375rem !important; // MUI style for this element is more specific. want to override it
  overflow-y: auto !important; // MUI style for this element is more specific. want to override it
}

.buttonValue {
  align-items: center;
  @include ellipsis;
}

.placeholderButton {
  display: flex;
}

.placeholderContent {
  padding: 0;
}

.placeholder {
  color: var(--text03) !important;
}

.startAdornment {
  padding: 0;
  display: flex;
  align-items: inherit;
}

.fontStyles {
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.5rem;
}

.noMargin {
  margin-top: 0 !important;
}

.actionsWrapper {
  display: none;
}

:hover > .buttonRightContent > .actionsWrapper {
  display: flex;
  max-height: 2.5rem;
}

.actionBtn {
  display: flex;
  justify-self: flex-end;
  align-items: center;
  margin-right: 0.625rem;
  cursor: pointer;

  &:last-child {
    margin-right: 0.3125rem;
  }
}

.close {
  color: #a1a4b1;
}

.disabled {
  cursor: auto;
}
