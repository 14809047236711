@use '@work4all/assets/theme/elevations.scss';

@import '@work4all/assets/theme/typography.scss';
@import '@work4all/assets/theme/breakpoints.scss';
@import '@work4all/assets/theme/spacing.scss';

.root {
  align-items: center;
  padding: 1rem;
  padding-top: calc(1rem + var(--w4a-safe-area-top, 0px));
  position: sticky;
  display: flex;
  width: 100%;
  top: 0;
  background: var(--ui01);
  @include elevations.elevationMainHead;
  z-index: 10;
}

.left {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 2rem;
  color: var(--text02);
  margin-right: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.search {
  background: var(--gray06);
}

.filtersButtonContainer {
  text-align: end;
  margin-left: 1rem;
}

.statisticSelector {
  color: var(--text03);
  display: flex;
  align-items: center;
  overflow: hidden;

  @media (max-width: $sm) {
    color: var(--text01);
  }

  &.clickable {
    cursor: pointer;
  }
}

.divider {
  padding: 0 $spaceXS;
}

.statisticTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.goBackButton {
  padding: 0;
  min-height: 3rem;
  width: 2.5rem;
  min-width: 2.5rem;
}

@media (max-width: 768px) {
  .left {
    @include heading4;
    color: var(--text02);
    margin-right: 1rem;
    display: flex;
    align-items: center;
  }
}
