.dialogRoot {
  width: min(90vw, 480px);
  max-width: unset;
}

.criteria {
  padding: 0.5rem 1rem;
  border: 1px solid var(--ui04);
  border-radius: 4px;
}

.infoBox {
  padding: 0.25rem 1rem;
  background: var(--bgShade3);
  border: 1px solid var(--alert);
  border-radius: 4px;
}

.circle {
  animation: circle 2s ease-in-out infinite;
}

@keyframes circle {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
