@use '@work4all/assets/theme/elevations.scss';
@use '@work4all/assets/theme/typography.scss';

@use '@work4all/assets/theme/spacing.scss';
@use '@work4all/assets/theme/breakpoints.scss';

.card {
  @include elevations.elevationWidget;
  background-color: var(--ui01);
  border-radius: 0.625rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.header {
  position: relative;
  padding: 0rem 1rem;
  height: 2.5rem;
  align-items: center;
}

.title {
  height: 100%;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text01);
  text-decoration: none;

  &:is(a) {
    &:hover {
      color: var(--brand01);
    }
  }
}

.action {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  height: 100%;
}

.actionsWrapper {
  display: flex;
  margin-right: -1rem;
  align-items: center;
}
