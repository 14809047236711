@import '@work4all/assets/theme/spacing.scss';

.body {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0 $spaceXS;
  padding-top: 0.75rem;
}

.divider {
  margin-top: 0.75rem;
}

.text {
  max-width: 316px;
  text-align: justify;
  text-justify: inter-word;
}
