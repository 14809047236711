@import '@work4all/assets/theme/spacing.scss';
@import '@work4all/assets/theme/typography.scss';
@import '@work4all/assets/theme/mixins.scss';

.root {
  display: flex;
  padding-right: $spaceS;
  justify-content: space-between;
}

.leftWrapper {
  display: flex;
  align-items: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-width: 0;
  justify-content: space-between;
}

.withBreadCrumbs {
  height: 100%;
}

.wrapperCentered {
  justify-content: center;
}

.breadcrumbs {
  color: var(--brand01);
  display: flex;
}

.heading {
  text-transform: unset;
  display: inline-block;
  width: 100%;
  @include ellipsis;

  &.downMd {
    font-size: 1rem;

    & .title {
      display: block;
    }

    & .subTitle {
      @include body1;
      color: var(--text03);
    }
  }
}

.navButton {
  min-height: $spaceXL;
  width: 2.5rem;
  min-width: 2.5rem;

  &.withBreadCrumbs {
    min-height: $spaceXXL;
  }
}

.divider {
  margin-left: $spaceXS;
  margin-right: $spaceXS;
  line-height: 1;
}
