.label {
  font-size: 0.85rem;
  line-height: 1rem;
  text-transform: uppercase;
  margin: 1rem;
}

.actions {
  display: none;
}

.list {
  max-height: calc(7 * (2rem));
  overflow-y: auto;
  overflow-x: hidden;
}

.row {
  height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 1rem;

  &:hover {
    &.actionRow {
      background-color: var(--ui02);
    }

    .actions {
      display: flex;
    }
  }

  &.active {
    background-color: var(--ui08);
    .actions {
      display: flex;
    }
  }

  &.layedOff .button {
    text-decoration: line-through;
  }

  .main {
    font-weight: bold;
  }
}

.button {
  flex: 1;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header {
  padding: 0;
}
