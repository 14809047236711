.input {
  width: calc(100% + 0.75rem);
  min-width: 0;
  outline: none;
  resize: none;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  margin: -0.4rem;
  padding: 0px 6px;
  pointer-events: none;
}

.inputEditMode {
  border: 0.1rem solid var(--text03);
  pointer-events: auto;
}
