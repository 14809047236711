@import '@work4all/assets/theme/elevations.scss';
@import '@work4all/assets/theme/spacing.scss';

.root {
  width: 32rem;
  padding: $spaceXS $spaceS;
  @include elevation6;
  border-radius: $spaceXS;
}

.header {
  display: flex;
  justify-content: space-between;
  height: $spaceXL;
  margin-bottom: $spaceS;
}

.heading {
  margin: 0;
  color: var(--text01);
}

.body {
  margin-bottom: $spaceS;
}

.footer {
  display: flex;

  .footerBtn {
    flex-grow: 1;
  }
}

.close {
  padding: $spaceXS;
  color: #a1a4b1;
}
