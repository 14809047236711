@use '@work4all/assets/theme/breakpoints.scss';

@import '@work4all/assets/theme/spacing.scss';
@import '@work4all/assets/theme/typography.scss';

.header {
  padding: 0 $spaceS;
  position: relative;

  display: grid;
  grid-template-columns: minmax(20px, 1fr) auto;
  grid-template-rows: min-content;
  padding-left: $spaceS;
  align-items: center;
  align-content: center;

  > a {
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    width: 100%;
  }
}

.titleContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: min-content;
  align-items: center;
  align-content: center;
}

.titleAlign {
  display: grid;
  grid-template-columns: auto 1fr;
  text-decoration: none;
  align-items: center;
  overflow: hidden;

  > a {
    text-decoration: none;
    color: var(--text01);

    &:hover {
      color: var(--brand01);
    }
  }
}
.title {
  @include heading4;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    color: var(--brand01);
  }

  & > a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    width: 100%;
    text-decoration: none;
    color: inherit;
  }
}

.titleMd {
  @include body1;
  color: var(--text01);
  font-weight: 400;
  line-height: 1.5rem;
  text-transform: capitalize;
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    color: var(--brand01);
  }

  & > a {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    color: inherit;

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      inset: 0;
      z-index: 0;
    }
  }
}

.action {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  height: 100%;
}

.actionsWrapper {
  display: flex;

  @media (min-width: breakpoints.$md) {
    margin-right: calc(-1 * $spaceS);
  }
}

.iconEnabled {
  color: var(--text01);
}

.iconDisabled {
  color: var(--text03);
}

.totalCount {
  margin-left: 0.5rem;
}

.dragHandle {
  height: 2rem;
}
