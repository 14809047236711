.maskForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--ui03);
}

.tabsWrapper {
  flex: 1;
  display: grid;
  overflow: auto;
  height: 100%;
}

.fullHeightPanel {
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}
