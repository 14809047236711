@import '@work4all/assets/theme/spacing.scss';

.root {
  padding: $spaceXS 1rem;
  background-color: var(--brand02);
  display: flex;
  box-shadow: none;
  border-radius: 0;
}

.body2 {
  font-weight: 700;
}

.seperator {
  width: 1rem;
}

.approved {
  background-color: var(--success);
}

.rejected {
  background-color: var(--error);
}
