@use '@work4all/assets/theme/elevations.scss';
@use '@work4all/assets/theme/breakpoints.scss';

.tableBody {
  width: 100%;
  overflow: auto;
}

.tableWrapper {
  overflow-y: hidden;
  background: var(--ui01);
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: breakpoints.$md) {
    height: 100%;
    overflow-y: auto;
  }

  @include elevations.elevation3;
  position: relative;
  z-index: 10;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
