@import '@work4all/assets/theme/spacing.scss';
@import '@work4all/assets/theme/breakpoints.scss';

.tableWrapper {
  position: relative;
  overflow: auto;
  flex: 1 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.root {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 77px);
  width: 100%;
  overflow: hidden;
  background-color: var(--ui01);
}

.controlBar {
  background-color: var(--ui01);
  padding: $spaceS;
  display: flex;
  gap: $spaceS;
}

.inputTimeFrame {
  width: 150px;
}

.wrap {
  display: flex;
  overflow: auto;
  height: 100%;
}

.selectionWrap {
  width: 400px;
  border-right: 1px solid var(--ui04);
}

.tableControlWrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.TimeTrackingInfoCardWrap {
  display: flex;
  & h4 {
    padding: $spaceS 0 0 $spaceS;
  }
}

.BorderRight {
  border-right: 1px solid var(--ui04);
}

.BorderBottom {
  border-bottom: 1px solid var(--ui04);
}

.cellPadding {
  padding: var(--basicTableCellPadding);
}

.NoPadding {
  padding: 0 !important;
}

.red {
  color: red;
}

.sideBySide {
  height: 100%;
  display: flex;
  overflow: auto;
}

.tableHead {
  border-bottom: 1px solid var(--ui04);
  color: var(--text03);
  text-align: center;
}

.picker {
  width: 15rem;

  @media (max-width: $sm) {
    width: 100%;
  }
}
