@mixin heading1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  font-size: 2.75rem;
  line-height: 4rem;
  display: flex;
  align-items: center;
  color: var(--text01);
}

@mixin heading2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 3rem;
  display: flex;
  align-items: center;
  color: var(--text01);
}

@mixin heading3 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  color: var(--text01);
}

@mixin heading4 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0rem;
  display: flex;
  align-items: center;
  color: var(--text01);
}

@mixin headingSmall {
  letter-spacing: 0.0625px;
  text-transform: uppercase;
  font-size: 0.625rem;
  line-height: 1rem;
}

@mixin body1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--text01);
}

@mixin body1TextLink {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  text-decoration: underline;
  color: var(--text01);
}

@mixin body2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  color: var(--text01);
}

@mixin body2SemiBold {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  color: var(--text01);
}

@mixin subtitle1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 2.5rem;
  color: var(--text01);
}

@mixin subtitle1TextLink {
  @include subtitle1;
  text-decoration: underline;
}

@mixin caption {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 1rem;
  color: var(--text01);
}

@mixin quote {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--text01);
}

@mixin buttonText {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-size: 1rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: var(--text01);
}

@mixin label {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  color: var(--text01);
}

@mixin tableHeading {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 0.8rem;
  color: var(--text03);
}
