@use '@work4all/assets/theme/typography.scss';
@use '@work4all/assets/theme/mixins.scss';

.header {
  @include mixins.mainHeadDropshadow;
  height: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
}

.radioLabel {
  @include typography.body2;
  color: var(--text02);
}

.day {
  width: 100%;
  height: 100%;
  padding: 0.25rem 0.5rem;
  text-align: start;
  color: var(--text01);

  :global(.mbsc-calendar-day-outer)
    &:not(.approved, .pending, .selected, :global(.mbsc-range-hover) &) {
    opacity: 40%;
  }

  &.holiday:not(.today, .selected) {
    background: repeating-linear-gradient(
      -48deg,
      transparent,
      transparent 0.25rem,
      var(--ui04) 0.25rem,
      var(--ui04) 0.5rem
    );

    :global(.mbsc-range-hover) & {
      background: var(--brand01);
      opacity: 40%;
      color: var(--textInverse);
    }
  }

  :global(.mbsc-range-hover) &:not(.selected) {
    background-color: var(--brand01);
    color: var(--textInverse);

    .colleagues {
      color: var(--textInverse);
    }

    &.approved,
    &.pending {
      opacity: 40%;
      color: var(--textInverse);
    }
  }

  &.today {
    background-color: var(--ui08);
  }

  &.selected {
    background-color: var(--brand01);
    color: var(--textInverse);

    &.excluded {
      opacity: 40%;
    }

    &.firstHalf {
      background: linear-gradient(
        to top left,
        #77b7eb 0,
        #77b7eb 50%,
        #499ce0 50%,
        #499ce0 100%
      );
    }

    &.secondHalf {
      background: linear-gradient(
        to bottom right,
        #77b7eb 0,
        #77b7eb 50%,
        #499ce0 50%,
        #499ce0 100%
      );
    }

    .colleagues {
      color: var(--textInverse);
    }
  }

  &.approved:not(.selected) {
    color: var(--success);
  }

  &.pending:not(.selected) {
    color: var(--alert);
  }

  .disabled {
    pointer-events: none;
    opacity: 1;
    cursor: default;
  }

  .monthDay {
    // TODO Figma styles don't match our typography/heading4 styles.
    //styleName: Heading/H4;
    font-family: 'Roboto', sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1rem;
  }

  .weekDay {
    // TODO Figma styles don't match our typography/caption styles.
    //styleName: Body / Caption;
    font-family: 'Roboto', sans-serif;
    font-size: 0.85rem;
    font-weight: 400;
    line-height: 1rem;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.25em;
  }

  .colleagues {
    color: var(--text03);
    display: flex;
    align-items: flex-start;
    gap: 0.25em;
  }
}

.info {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.calendar {
  &.disabled :global(.mbsc-calendar-cell) {
    pointer-events: none;
  }

  :global {
    .mbsc-calendar-table {
      border-collapse: collapse;
      border: 1px solid var(--ui04);
    }

    .mbsc-calendar-week-days {
      display: none;
    }

    .mbsc-calendar-cell {
      // HACK Set border-width to 0.01px to avoid display scaling issues.
      // Setting border-width to 1px results in inconsistent display scaling
      // when combined with border-collapse: collapse.
      // Instead set it to 0.01px, which will always be rounded to 1px.
      border: 0.01px solid var(--ui04);
      height: 4rem;
      isolation: isolate;
    }

    .mbsc-calendar-cell-inner {
      border: none;
    }

    .mbsc-range-day::after,
    .mbsc-range-hover::before {
      display: none;
    }
  }
}
