.textWrapper {
  padding: 0 1rem 0 0.5rem;
  display: flex;
  width: 100%;
  align-items: center;
  height: 3.75rem;
  border-bottom: 1px solid var(--widgetBorder);
  &.smallPadding {
    padding: 0 0.25rem 0 0.5rem;
  }
}

.list-picker-filter-input {
  border-bottom: none !important;
}
