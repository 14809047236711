@import '@work4all/assets/theme/spacing.scss';
@import '@work4all/assets/theme/typography.scss';

@import '@work4all/assets/theme/breakpoints.scss';

.card-wrapper {
  margin-bottom: $spaceS;

  &:last-child {
    margin-bottom: 0;
  }
}

.heading {
  margin-top: 3rem;
  margin-bottom: 0.5rem;
  @media (min-width: $lg) {
    margin-left: 1rem;
  }
}

.button {
  width: 100%;
}

.max-width {
  width: 100%;
}

.half-width {
  width: 50%;
}
