.root {
  position: relative;
  width: 100%;
}

.outerButton {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  color: var(--textInverse);
  padding-left: 1rem;
  padding-right: 3rem;
  width: 100%;
  min-height: 3.25rem;
  border-radius: 0;

  &.isButtonMode {
    width: max-content;
    min-height: 3.25rem;
    border-radius: 0.5rem;
    border: 1px solid var(--textInverse);

    &:not(.disabled) {
      &.initial {
        background-color: var(--errorLight);
        border: 1px solid var(--error);
        color: var(--error);
      }

      &.running {
        padding-right: 5rem;
        background-color: transparent;
      }

      &.paused {
        background-color: transparent;
      }

      &.stopped {
        background-color: transparent;
      }

      &.warning {
        background-color: var(--errorLight);
        border: 1px solid var(--error);
        color: var(--error);
      }
    }
  }

  &.cardHeader {
    color: var(--text03);
    border: none;
    width: 100%;

    &:not(.disabled) {
      &.initial {
        background-color: transparent;
        border: none;
        color: var(--error);
      }

      &.warning {
        background-color: transparent;
        border: none;
        color: var(--error);
      }
    }
  }

  &.disabled {
    background-color: var(--ui04);
    color: var(--text03);
  }

  &:not(.disabled) {
    &.initial {
      background-color: var(--error);
    }

    &.running {
      background-color: var(--success);
    }

    &.paused {
      background-color: var(--alert);
    }

    &.stopped {
      background-color: var(--ui06);
    }
  }

  &:not(.isButtonMode) {
    pointer-events: none;
  }
}

.innerButton {
  padding: 0;
  min-width: 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  color: white;

  &.stop {
    right: 8.5rem;
  }

  &.isButtonMode {
    right: 0.5rem;

    &.stop {
      right: 2.5rem;
    }
    span {
      margin-right: 0;
      border-radius: 50%;
    }
  }

  &:not(.disabled) {
    &.start {
      color: var(--error);
    }
  }

  &.disabled {
    color: var(--text03);
  }
}

.label {
  margin-right: auto;
  font-weight: 400;
}
