@import '@work4all/assets/theme/spacing.scss';

.favoriteLinksRoot {
  padding-right: 1rem;
  flex: 1;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow-x: hidden;
}

.navWrapper {
  display: flex;
  height: 2.5rem;

  & > *:first-child {
    margin: 0;
  }

  & .dropTarget:last-of-type {
    width: 0;
  }
}

.dropTarget {
  padding: 0.25rem 0.625rem;
  position: relative;
  min-width: 0;

  &.start {
    padding: 0.25rem 0.25rem;
  }

  &.end {
    flex: 1;
  }

  &::after {
    content: '';
    display: block;
    width: 0.25rem;
    height: 100%;
    border-radius: 0.125rem;
  }

  &.dndActive::after {
    background: var(--ui01);
  }
}

.emptyDropTarget {
  min-width: 100%;
}

.navItem {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-size: 0.875rem;
  line-height: 1.225rem;
  text-transform: uppercase;
  padding: $spaceXS $spaceS;
  border-radius: 0.3125rem;
  color: white;

  &.active {
    background-color: #ffffff4b;

    & > svg {
      color: white;
    }
  }
}

.navItemInput {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-size: 0.875rem;
  line-height: 1.225rem;
  text-transform: uppercase;
  min-width: 0;
  padding: $spaceXS $spaceS;
  border-radius: 0.3125rem;
  border: none;
  outline: none;
  color: var(--textInverse);
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 1;
}

.folderIcon {
  flex: none;
  width: 1.5rem;
  height: 1.5rem;
}

.menuList {
  padding: 0;
}

.menuItem {
  padding: 0.75rem 1rem;
}

.divider {
  flex: none;
  width: 0.125rem;
  height: 1.5rem;
  margin: 0 0.75rem 0 0.5rem;
  background-color: white;
  border-radius: 0.125rem;
  position: relative;

  &.hidden {
    display: none;
  }
}

.linksWrapper {
  display: flex;
  flex: 1;
  min-width: 0;
  height: 2.75rem;
  border: 0.125rem dashed transparent;
  border-radius: 0.25rem;
  padding-right: 0.625rem;

  &.dndActive {
    border-color: var(--ui01);
  }
}

.overflowList {
  align-items: center;
}
