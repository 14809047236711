@use '@work4all/assets/theme/breakpoints.scss';

.right {
  @media (min-width: breakpoints.$lg) {
    flex-basis: 37%;
    width: 37%;
  }
}
.infoIcon {
  color: var(--brand01);
  margin-left: 0.5rem;
  margin-top: 0.75rem;
  width: 1rem;
  height: 1rem;
  align-self: center;
}
