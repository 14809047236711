@import '@work4all/assets/theme/elevations.scss';
@import '@work4all/assets/theme/spacing.scss';

.navigation {
  position: fixed;
  z-index: 500;
  left: 0;
  bottom: 0;
  background-color: var(--ui06);
  width: 100%;
  overflow-x: auto;
  height: calc(4rem + var(--w4a-safe-area-bottom, 0px));
  padding-bottom: var(--w4a-safe-area-bottom, 0px);
  &.hidden {
    display: none;
  }

  @include elevation6;

  &__inner {
    width: 100%;
    justify-content: space-around;
    margin: auto;
    height: 100%;
    display: flex;

    a {
      display: flex;
      flex-direction: column;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

a.navigation__item {
  &.navigation__item {
    text-transform: capitalize;
    color: var(--textInverse);
    width: 100%;

    & > .title {
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    svg {
      width: 24px;
      height: 24px;

      path {
        fill: var(--textInverse);
      }
    }

    svg {
      &:global(.icon--more) {
        path:nth-child(1) {
          stroke: var(--textInverse);
          fill: unset;
        }
        path:nth-child(2) {
          stroke: var(--textInverse);
          fill: unset;
        }
        path:nth-child(3) {
          fill: var(--textInverse);
        }
      }
    }
  }
}

a {
  &.active {
    color: var(--projectPrimaryLightColor);
  }
}
.active {
  svg {
    path {
      fill: var(--projectPrimaryLightColor);
    }
  }
  svg {
    &:global(.icon--more) {
      path:nth-child(1) {
        stroke: var(--projectPrimaryLightColor);
        fill: unset;
      }
      path:nth-child(2) {
        stroke: var(--projectPrimaryLightColor);
        fill: unset;
      }
      path:nth-child(3) {
        fill: var(--projectPrimaryLightColor);
      }
    }
  }
}
