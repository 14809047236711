@import '@work4all/assets/theme/typography.scss';

.caption {
  &--sm {
    @include caption;
    color: var(--text03);
  }

  &--up-sm {
    @include body2;
    color: var(--text03);
  }

  text-transform: none;
}
