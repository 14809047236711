@use '@work4all/assets/theme/elevations.scss';

.textWrapper {
  padding: 0 1rem 0 0.5rem;
  display: flex;
  width: 100%;
  align-items: center;
  height: 3.75rem;
  border-bottom: 1px solid var(--widgetBorder);
}

.filter-input {
  border-bottom: none !important;
}
