@import '@work4all/app/containers/mask-overlays/mask-overlay/variables.scss';
@import '@work4all/assets/theme/spacing.scss';

.wrapperInner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: $spaceS;
}

.root {
  width: 100%;
}

.collapseContainer {
  padding: 0 $sectionHorizontalPadding;
}

.noteWrapper {
  grid-column: span 2;
}
