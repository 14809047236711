@use '@work4all/assets/theme/breakpoints.scss';
@import '@work4all/assets/theme/spacing.scss';

$previewWidth: 500px;

.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  isolation: isolate;
  padding-right: var(--w4a-safe-area-right, 0px);
  padding-left: var(--w4a-safe-area-left, 0px);

  &__header-slot {
    position: relative;
    z-index: 21;
    flex: 0;

    padding-top: var(--w4a-safe-area-top, 0px);

    @media (min-width: breakpoints.$md) {
      padding-top: 0;
    }
  }

  &__body-slot {
    z-index: 1;
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
    display: flex;
    min-height: 0;
    position: relative;

    &.activeDrop {
      flex: 1 0 0;
      overflow: hidden;
    }

    &-mobileView {
      flex-direction: column;
      overflow: auto;
    }
  }
}

.widgetControllerWrap {
  flex-grow: 1;
  padding: 0; // 0 $spaceS
  overflow: auto;
  &Md {
    padding: 0;
    padding-bottom: 1rem;
  }
  position: relative;
}

.resizable {
  position: sticky;
  max-width: 50vw;
  min-width: min(50vw, min(100vw, 31.25rem));

  .previewPanelWrap {
    width: 100%;
    height: 100%;
    border-top: 1px solid var(--ui03);
  }
}

.previewPanelWrap {
  overflow-y: auto;
  background-color: var(--ui01);
  border-left: 1px solid var(--ui04);
  border-top: 1px solidvar(--ui03);

  &.active {
    background-color: var(--ui08);
  }

  &.fixedSize {
    height: calc(100vh - 9rem);
  }

  &.maxHeightDialog {
    height: 100%;
  }
}

.scrollButton {
  position: fixed;
  z-index: 101;
  bottom: 5rem;
  right: $spaceL;
  width: 56px;
  background: var(--brand01);
  color: var(--ui01);

  & > a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &:hover {
    background: var(--brand02);
  }
}

.scrollButtonMd {
  position: fixed;
  z-index: 101;
  bottom: 5rem;
  right: $spaceS;
  width: 56px;
  background: var(--brand01);
  color: var(--ui01);

  & > a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &:hover {
    background: var(--brand02);
  }
}

.mobileSubNavigationList {
  background-color: var(--ui01);
  padding: 0;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.banner {
  padding: 0.25rem 1rem;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  color: white;
  background: var(--error);
}
