@import '@work4all/assets/theme/typography.scss';
@import '@work4all/assets/theme/spacing.scss';

/*******************************************************************************************/

.link {
  @include body1;
  color: var(--brand01);
  cursor: pointer;
  text-decoration: none;
}

/*******************************************************************************************/

.contact-buttons {
  display: flex;
  flex-wrap: wrap;
  column-gap: $spaceM;
  width: 100%;

  .contact-link-button {
    @include body1;
    color: var(--brand01);
    column-gap: $spaceXXS;
    cursor: pointer;

    .contact-body1 {
      color: var(--brand01);
    }
  }
}

/*******************************************************************************************/

.category-layout {
  display: flex;
  flex-direction: column;
  column-gap: $spaceXS;
  row-gap: $spaceXS;
}

.category {
  @include body1;
  display: flex;
  column-gap: $spaceXXS;

  & > .secondary {
    color: var(--text03);
    width: 150px;
    min-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.overflow-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blueAction {
  color: var(--brand01);
  cursor: pointer;
}

.preLine {
  white-space: pre-line;
}

.categoryHeadWrapper {
  display: flex;
  justify-content: space-between;
}

.label {
  font-size: 0.85rem;
  line-height: 1rem;
  text-transform: uppercase;
  margin: 1rem;
}

.categoryContentWrapper {
  display: grid;
  grid-template-columns: repeat(var(--columns, 1), 1fr);
}

.grid-div {
  display: inline-grid;
  grid-template-columns: repeat(12, 1fr);

  .grid-entity-column {
    grid-column: 1 / 11;
  }

  .grid-file-column {
    grid-column: 12 / 12;
    margin: auto;
  }
}

.hide {
  display: none;
}
