@import '@work4all/assets/theme/spacing.scss';
@import '@work4all/assets/theme/elevations.scss';

.root {
  font-family: 'Roboto', sans-serif;
  padding: 0;
  border-radius: $spaceXS;
  @include elevation6;
}

.pickerSize {
  min-width: 100px;
  height: 2.25rem;
}

.listItemButton {
  height: 100%;
}
