@use '@work4all/assets/theme/spacing.scss';

.topBar {
  padding: 0 1rem;
  border-bottom: 1px solid var(--ui04);
}

.chipsContainer {
  display: flex;
  margin-top: spacing.$spaceS;
  min-height: spacing.$spaceM;
  flex-wrap: wrap;
  gap: spacing.$spaceXXS spacing.$spaceXS;
}

.container {
  position: relative;
}

.minimalToggle {
  border: none;
  text-transform: none;
  font-weight: 400;

  &.Mui-selected {
    font-weight: 700;
  }
}
