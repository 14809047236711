.resourceColHeader {
  width: 100%;

  .cta {
    visibility: hidden;
    position: absolute;
    right: 0;
    top: -0.25rem;
  }

  &:hover {
    .cta {
      visibility: visible;
    }
  }
}

.cellWrap {
  cursor: pointer;
  display: flex;
  flex-direction: row;

  .editIcon {
    display: none;
  }

  &:hover {
    .editIcon {
      display: inline-block;
      position: absolute;
      font-size: 1rem;
      margin-top: 0.25rem;
      color: var(--text03);
    }
  }
}
