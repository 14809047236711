.table {
  flex: 1;
}
.tbody {
  max-height: 347px;
  border-bottom: 1px solid var(--ui04);
  overflow-y: hidden;
}

.thead {
  border-left: 1px solid;
}

.collapse {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.collapseInner {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding-right: 2rem;
}

.rowSizePicker {
  min-width: 80px;
  padding-bottom: 0.5rem;
}

.positionTitle {
  display: flex;
  align-items: center;
}

.backgroundCell {
  background-color: var(--ui03);
}

.paddingButton {
  padding: 0.72rem;
}

.clearButton {
  padding-bottom: 0.5rem;
  display: flex;
}
