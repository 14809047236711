.container {
  height: 100%;
}

.contentContainer {
  background-color: var(--bgShade1);
  height: 100%;
  overflow: hidden;
  padding: 4px 8px;
  position: relative;

  .row {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    p {
      display: inline;
    }
  }
  .timeBox {
    overflow: visible;
    margin-left: 0.5rem;
    white-space: nowrap;
  }
  .firstLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 8px;
    background: rgb(203, 229, 254);
    background: linear-gradient(0deg, var(--ui01) 0%, var(--ui04) 100%);
  }
  border-radius: 4px;

  &.fixed {
    height: 31px;

    &::after {
      display: none;
    }
  }

  &.suggested {
    background: repeating-linear-gradient(
      132deg,
      transparent,
      transparent 0.25rem,
      var(--ui02) 0.25rem,
      var(--ui02) 0.5rem
    );
    border-radius: 0px 4px 4px 0px;
    padding-top: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &::after {
      background: var(--ui01);
      background: linear-gradient(0deg, var(--ui01) 0%, var(--ui04) 100%);
    }
  }
}

.addContainer {
  float: left;
  width: 40px;
  background-color: var(--ui02);
  color: var(--text01);
  border-radius: 4px 0px 0px 4px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.fixed {
    height: 31px;
  }
}

.durationAndHideIcon {
  display: flex;
  gap: 20px;
  align-items: center;
}

.comment {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  white-space: pre-wrap;
}

.secondRowContainer {
  display: flex;
  padding-top: 0.2rem;
}
