@use '@work4all/assets/theme/elevations.scss';
@use '@work4all/assets/theme/typography.scss';

.dialog {
  @include elevations.elevation6;

  min-width: 320px;
  max-width: 350px;
}

.dialog-header {
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dialog-title {
  @include typography.heading4;

  padding: 0;
  flex: 1;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  svg {
    color: var(--text03);
  }
}

.dialog-button {
  margin: -0.5rem;
}

.dialog-content {
  @include typography.body1;

  flex: 1 1 auto;
  overflow-y: auto;
  padding: 1rem;
}

.dialog-header + .dialog-content {
  padding-top: 0;
}

.dialog-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 auto;
  gap: 0.5rem;
  padding: 0 1rem 1rem 1rem;

  & > * {
    flex: 1;
  }
}
