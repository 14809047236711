@import '@work4all/assets/theme/mixins.scss';

@import '@work4all/assets/theme/spacing.scss';
@import '@work4all/assets/theme/elevations.scss';

.with-dropdown {
  $self: &;
  position: relative;
  border-radius: 0.5rem;

  &__input {
    composes: searchbar__input-el from '../../input/search-bar/SearchBar.module.scss';

    &--active {
      &::placeholder {
        opacity: 0.5;
        color: var(--text01);
      }
    }
  }

  &__search-bar {
    composes: searchbar from '../../input/search-bar/SearchBar.module.scss';
    z-index: 101;

    &--active {
      @include active-input-state;
      border-radius: 0.5rem;
    }

    #{$self}--dropdown-visible {
      border-radius: 0;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }

    #{$self}--mobile & {
      background-color: var(--ui04);
      border-radius: 0.25rem;

      &--active {
        @include active-input-state;
        @include elevation3;
      }
    }
  }

  &__items {
    max-height: 16rem;
    overflow: auto;
  }

  &--dropdown-visible {
    @include input-drop-shadow;
    border-radius: 0.5rem;
  }

  &--mobile {
    padding: $spaceS;
    margin-left: -$spaceS;
    margin-right: -$spaceS;
    border-radius: 0.25rem;

    &#{$self}--active {
      background-color: var(--ui03);
      margin-top: -$spaceS;
      margin-bottom: -$spaceS;
      border-radius: 0;
    }
  }

  &--default-view {
    &#{$self}--active {
      @include active-input-state;
    }
  }
}

.searchBarNoBorderRadius {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.items {
  margin-top: $spaceS;
  background-color: var(--ui01);
  margin-left: -$spaceS;
  margin-right: -$spaceS;
  margin-bottom: -$spaceS;
}
