.bottomWrapper {
  position: sticky;
  bottom: 0;
  padding: 2.5rem;
  margin-top: auto;
}

.alert {
  white-space: pre-line;
}
