@use '@work4all/assets/theme/elevations.scss';
@use '@work4all/assets/theme/breakpoints.scss';

.popover-paper-root {
  height: Min(400px, calc(100% - 32px));
  width: Min(1000px, calc(100% - 32px));
  @include elevations.elevation6;
  border-radius: 0.5rem;
}

.picker {
  height: 100%;
  background-color: var(--ui03);
}

.resizable {
  background-color: var(--dayUIBackground);

  display: flex;
  flex-direction: column;
}

.actionBar {
  padding: 0 1rem;
  flex: none;
}

.tableWrapper {
  overflow-y: hidden;
  background: var(--ui01);
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;

  @media (max-width: breakpoints.$md) {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 6rem;
  }

  @include elevations.elevation3;
  position: relative;
  z-index: 10;
}
