@import '@work4all/assets/theme/spacing.scss';

.popoverRoot {
  pointer-events: none;
}

.popoverPaper {
  pointer-events: auto;
  font-family: 'Roboto', sans-serif;
  padding: 0;
  border-radius: $spaceXS;
}
