@import '@work4all/assets/theme/spacing.scss';

.wrap {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 $spaceS $spaceS $spaceS;
  column-gap: $spaceS;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  font-size: 1rem;
}

.label {
  color: var(--text03);
  min-height: 2rem;
  display: flex;
  align-items: center;
}

.link {
  color: var(--brand01);
  text-decoration: none;
  cursor: pointer;
}

.dialogWrapper {
  max-width: 600px;
}

.value {
  display: flex;
  align-items: center;
}

.permitsValue {
  gap: 0.75rem;
}

.two-lines {
  padding: 0.25rem 0;
}
