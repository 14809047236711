.deselect-icon {
  width: 24px;
  height: 24px;
  color: var(--ui05);
  margin-left: 0.5rem;
  flex: none;
}

.list-item-button-active {
  background-color: var(--ui02);
}

.favActive {
  color: var(--brand02);
}

.list-item-button {
  height: 100%;
  max-width: 100%;
}

.nothing-found {
  text-align: center;
  font-style: italic;
}

.list-item-disabled {
  opacity: 1 !important;
  padding: 0;
}

.list-item-content-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  & > * {
    flex: 1;
    overflow: hidden;
  }
}
