.controls {
  height: 100%;
  max-height: 100%;
  width: 1rem;
  flex: none;
  display: flex;
  flex-direction: column;
  margin-right: -0.25rem;
  margin-left: 0.25rem;
}

.increment,
.decrement {
  background-color: var(--ui02);
  width: 100%;
  flex: 1;
  outline: 1px solid transparent;

  &:hover {
    outline-color: var(--brand01);
    color: var(--brand01);
  }

  &:disabled {
    opacity: 0.5;
  }
}

.increment {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.decrement {
  margin-top: 1px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.disabled {
  opacity: 0.5;
}

.icon {
  font-size: 1rem;
}
