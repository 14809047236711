.actionWrapper {
  padding: 0.5rem 0.75rem;
  display: flex;
  cursor: pointer;

  .name {
    margin-left: 0.25rem;
  }
}

.actionWrapper:hover {
  background-color: var(--ui03);
}
